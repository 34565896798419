import { useState } from 'react'
import Organization from './Organization'
import SignCode from './SignCode'
import './styles.sass'
import SuccessSigned from './SuccessSigned'

export enum ContractStatus {
  data = 'data',
  code = 'code',
  success_signed = 'success_signed'
}

export default function Contract () {
  const [contractStatus, setContractStatus] = useState<ContractStatus>(ContractStatus.data)

  if (contractStatus === ContractStatus.data) {
    return <Organization setContractStatus={setContractStatus} />
  } else if (contractStatus === ContractStatus.code) {
    return <SignCode setContractStatus={setContractStatus} />
  } else if (contractStatus === ContractStatus.success_signed) {
    return <SuccessSigned />
  } else {
    return <></>
  }
}
