import { Button, Container } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import './styles.sass'
import { useAuthContext } from 'AuthContext'
import { MainButton } from '@autos/autos-common-fe'

export default function SuccessSigned () {
  const { logout } = useAuthContext()
  return (
    <>
      <Container maxWidth='md'>
        <div className='successSigned'>
          <div className='successSigned__icon'>
            <svg width='91' height='92' viewBox='0 0 91 92' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M83.4167 42.5116V45.9999C83.412 54.1763 80.7644 62.1322 75.8687 68.681C70.973 75.2298 64.0916 80.0205 56.2507 82.3388C48.4098 84.6572 40.0296 84.3788 32.3598 81.5452C24.6901 78.7116 18.1418 73.4747 13.6915 66.6154C9.24128 59.7562 7.12752 51.6421 7.6655 43.4834C8.20349 35.3247 11.3644 27.5585 16.6768 21.343C21.9892 15.1275 29.1685 10.7957 37.1439 8.99378C45.1193 7.19184 53.4635 8.01625 60.9321 11.3441' stroke='#6DAE43' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
              <path d='M83.4167 15.6665L45.5 53.6211L34.125 42.2461' stroke='#6DAE43' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
          </div>
          <h1>Договор успешно подписан!</h1>
          <h4>Вы успешно подписали договор. Осталось совстем немного. Наши специалисты скоро его проверят и сообщат вам.</h4>
          <div><MainButton sx={{ width: '20em', mb: '32px' }} onClick={() => { logout() }}>Следить за статусом</MainButton></div>
          <div>
            <Button variant='text' startIcon={<DescriptionOutlinedIcon />} style={{ textTransform: 'none', fontSize: '16px', fontWeight: '500', marginBottom: '100px' }} disabled>
              Скачать подписанный договор
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}
