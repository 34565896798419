/// <reference path='ymaps.d.ts' />

const API_KEY = '410243e4-8040-41ff-9553-64940e0a2e18'
export const ID_YANDEX_MAP = 'yandexMap'

export default async function getMapApi (): Promise<typeof ymaps> {
  const w = window as any
  if (document.getElementById('ymaps-script') !== null) return w.ymaps

  const apiKey = API_KEY
  const lang = 'ru-RU'
  const version = '2.1.78'
  const coordorder = 'latlong'
  const debug = false
  const mode = debug ? 'debug' : 'release'
  const src = `https://api-maps.yandex.ru/${version}/?lang=${lang}&apikey=${apiKey}&mode=${mode}&coordorder=${coordorder}`

  return new Promise((resolve, reject) => {
    const script = w.document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('async', '')
    script.setAttribute('defer', '')
    script.setAttribute('id', 'ymaps-script')
    script.onload = () => w.ymaps ? w.ymaps.ready(() => resolve(w.ymaps), reject) : reject(Error('Could not find ymaps object'))
    script.onerror = reject
    w.document.head.appendChild(script)
  })
}
