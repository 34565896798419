import { OrganizationStatus } from './OrganizationStatus'

export enum TypeLegalPerson {
  st = 'st',
  organization = 'organization'
}

export interface OrganizationInterface {
  id: string
  userId: string
  status: OrganizationStatus
  typeLegalPerson: TypeLegalPerson
  inn: string
  fullName: string
  ogrnip: string
  ogrn: string
  correspondentAccountNumber: string
  kpp: string
  checkingAccountNumber: string
  bik: string
  bankName: string
  organizationHeadName: string
  legalAddress: string
  address: string
  coordinates: [number, number]
}
