import { Button, IconButton } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import './styles.sass'
import { useAuthContext } from 'AuthContext'

export default function Navbar () {
  const { logout } = useAuthContext()

  return (
    <>
      <div className='Navbar'>
        <div className='Navbar__bar'>
          <div className='Navbar__bar_left'>
            <svg width='69' height='45' viewBox='0 0 69 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect x='1' y='1' width='67' height='43' rx='7' fill='white'/>
              <path d='M16 29L53 16M53 29L16 16' stroke='black' strokeWidth='2' strokeLinecap='round'/>
              <rect x='1' y='1' width='67' height='43' rx='7' stroke='black' strokeWidth='2'/>
            </svg>
            <h1>AutoS</h1>
          </div>
          <div className='Navbar__bar_right'>
            <IconButton sx={{ p: 0, width: '24px', height: '24px', m: '0 23px', color: 'black' }}>
                <SettingsOutlinedIcon />
            </IconButton>
            <IconButton sx={{ p: 0, width: '24px', height: '24px', m: '0', color: 'black' }}>
                <NotificationsOutlinedIcon />
            </IconButton>
            <IconButton sx={{ p: 0, width: '24px', height: '24px', m: '0 23px', color: 'black' }}>
              <PersonOutlineOutlinedIcon />
            </IconButton>
            <Button onClick={() => logout()}>Выйти</Button>
          </div>
        </div>
      </div>
    </>
  )
}
