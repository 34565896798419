import './styles.sass'
import { DataTable, Content, ToolbarButton, Status, StatusColor, ActionsMenu } from '@autos/autos-common-fe'
import { Add, Settings } from '@mui/icons-material'
import { OfferStatus } from 'api/OfferStatus'
import { useCallback, useEffect, useState } from 'react'
import apiList from 'api/my-offer/list'
import { OfferInterface } from 'api/OfferInterface'
import { useNavigate } from 'react-router-dom'
import apiDelete from 'api/my-offer/delete'
import { useAuthContext } from 'AuthContext'

const status = Status({
  presentation: {
    [OfferStatus.checking]: {
      color: StatusColor.orange,
      caption: 'Проверка'
    },
    [OfferStatus.active]: {
      color: StatusColor.green,
      caption: 'Активна'
    },
    [OfferStatus.disabled]: {
      color: StatusColor.red,
      caption: 'Отключена'
    }
  }
})

export default function MyServices () {
  const [rows, setRows] = useState<OfferInterface[]>()
  const { handleResponseSuccess } = useAuthContext()
  const navigate = useNavigate()

  const remove = useCallback(async (id: string) => {
    await apiDelete(id)
    const r = await apiList()
    setRows(r)
    handleResponseSuccess('Услуга удалена')
  }, [handleResponseSuccess])

  useEffect(() => {
    if (rows === undefined) {
      apiList().then(r => setRows(r))
    }
  }, [rows])

  return (
    <Content
      title="Мои услуги"
      toolbar={
        <ToolbarButton href="/my-services/new" endIcon={<Add />}>Добавить</ToolbarButton>
      }
    >
      <DataTable
        columns = { [
          {
            id: 'name',
            label: 'Название'
          },
          {
            id: 'status',
            label: 'Статус',
            format: status
          },
          {
            id: 'id',
            label: <Settings />,
            align: 'center',
            format: value => <ActionsMenu key={`${value}`} actions = {[
              {
                caption: 'Редактировать',
                onClick: () => navigate(`/my-services/${value}`)
              },
              {
                caption: 'Отключить',
                onClick: () => {}
              },
              {
                caption: 'Удалить',
                onClick: () => remove(value as string)
              }
            ]} />
          }
        ] }
        rows = { (start, end) => rows === undefined ? [] : rows.slice(start, end) }
        rowsCount = { rows === undefined ? 0 : rows.length }
      />
    </Content>
  )
}
