import { Button, Container, FormControlLabel, InputAdornment, Radio, RadioGroup } from '@mui/material'
import { Stack } from '@mui/system'
import { TypeLegalPerson } from 'api/OrganizationInterface'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import apiRegister, { Params as DataType } from 'api/organization/myOrganizationRegister'
import apiGet from 'api/organization/myOrganizationGet'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { Field, MainButton } from '@autos/autos-common-fe'
import './styles.sass'
import { useAuthContext } from 'AuthContext'
import { ContractStatus } from '..'
import ModalMap from 'ui/ModalMap'

const defaultData = {
  typeLegalPerson: TypeLegalPerson.organization,
  inn: '',
  fullName: '',
  ogrnip: '',
  ogrn: '',
  correspondentAccountNumber: '',
  kpp: '',
  checkingAccountNumber: '',
  bik: '',
  bankName: '',
  organizationHeadName: '',
  legalAddress: '',
  address: ''
}

interface Props {
  setContractStatus: React.Dispatch<React.SetStateAction<ContractStatus>>
}

export default function Organization ({ setContractStatus }: Props) {
  const { handleResponseFailure } = useAuthContext()
  const [data, setData] = useState<DataType>(defaultData)
  const [openModalMap, setOpenModalMap] = useState<boolean>(false)

  useEffect(() => {
    apiGet().then(result => {
      if (result !== null) {
        setData(result)
      }
    })
  }, [])

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({ ...prev, typeLegalPerson: (event.target.value as TypeLegalPerson) }))
  }

  const updateString = (key: keyof DataType) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [key]: e.target.value })
    }
  }

  const isOrganization = () => data.typeLegalPerson === TypeLegalPerson.organization

  const handleRegister = useCallback(() => {
    apiRegister(data).then(result => {
      if (!result) {
        handleResponseFailure('Невозможно сохранить данные.')
      } else {
        setContractStatus(ContractStatus.code)
      }
    })
  }, [data, handleResponseFailure, setContractStatus])

  return (
    <>
      { openModalMap && <ModalMap
        title='Уточнение фактического адреса'
        open={openModalMap}
        onClose={() => setOpenModalMap(false)}
        getAddress={(address, coordinates) => {
          setData(prev => ({ ...prev, address, coordinates }))
        }}
        viewAddress={data.address ?? ''}
      />}
      <Container maxWidth='md'>
        <div className='organization'>
          <h1>Подписание договора</h1>
          <h4>Прежде чем начать пользоваться системой, необходимо подписать договор.<br />
            Для этого введите данные своей компании.</h4>
          <Stack alignItems='center' spacing={2} sx={{ m: '0 32px 32px' }}>
            <RadioGroup
              row
              value={data.typeLegalPerson}
              onChange={handleRadioChange}
            >
              <FormControlLabel value={TypeLegalPerson.organization} control={<Radio />} label='Общество с ограниченной ответственностью' />
              <FormControlLabel value={TypeLegalPerson.st} control={<Radio />} label='Индивидуальный предприниматель' />
            </RadioGroup>
          </Stack>
          <div className='organization__row'>
            <Field
              type='text'
              label={isOrganization() ? 'Полное наименование организации' : 'ФИО' }
              fullWidth
              size='small'
              value={data.fullName}
              onChange={updateString('fullName')}
            />
          </div>
          { isOrganization() && <div className='organization__row'>
            <Field
              type='text'
              label='ФИО директора'
              fullWidth
              size='small'
              value={data.organizationHeadName}
              onChange={updateString('organizationHeadName')}
            />
          </div>}
          <div className='organization__row'>
            <Field
              type='text'
              label='Фактический адрес'
              fullWidth
              size='small'
              value={data.address}
              onClick={() => setOpenModalMap(true)}
              InputProps={ data.address !== ''
                ? {
                    startAdornment: <InputAdornment position='start'>
                  <MapOutlinedIcon />
                </InputAdornment>
                  }
                : undefined}
            />
          </div>
          <div className='organization__row'>
            <Field
              type='text'
              label='Юридический адрес'
              fullWidth
              size='small'
              value={data.legalAddress}
              onChange={updateString('legalAddress')}
            />
          </div>
          <div className='organization__row'>
            <Field
              type='text'
              label='ИНН'
              sx={{ width: '50%', mr: '16px' }}
              size='small'
              value={data.inn}
              onChange={updateString('inn')}
            />
            { isOrganization()
              ? <Field
                  type='text'
                  label='ОГРН'
                  size='small'
                  sx={{ width: '50%' }}
                  value={data.ogrn}
                  onChange={updateString('ogrn')}
                />
              : <Field
                  type='text'
                  label='ОГРНИП'
                  size='small'
                  sx={{ width: '50%' }}
                  value={data.ogrnip}
                  onChange={updateString('ogrnip')}
                />}
          </div>
          <div className='organization__row'>
            <Field
              type='text'
              label='Расчетный счет'
              sx={{ width: '50%', mr: '16px' }}
              size='small'
              value={data.checkingAccountNumber}
              onChange={updateString('checkingAccountNumber')}
            />
            <Field
              type='text'
              label='Корр счет'
              sx={{ width: '50%' }}
              size='small'
              value={data.correspondentAccountNumber}
              onChange={updateString('correspondentAccountNumber')}
            />
          </div>
          <div className='organization__row'>
            <Field
              type='text'
              label='Наименование банка'
              fullWidth
              size='small'
              value={data.bankName}
              onChange={updateString('bankName')}
            />
          </div>
          <div className='organization__row'>
            <Field
              type='text'
              label='КПП'
              sx={{ width: '50%', mr: '16px' }}
              size='small'
              value={data.kpp}
              onChange={updateString('kpp')}
            />
            <Field
              type='text'
              label='БИК'
              sx={{ width: '50%' }}
              size='small'
              value={data.bik}
              onChange={updateString('bik')}
            />
          </div>
          <div className='organization__row'>
            <div className='organization__download'>
              <Button variant='text' startIcon={<DescriptionOutlinedIcon />} style={{ textTransform: 'none', fontSize: '16px', fontWeight: '500' }} disabled>
                Скачать образец договора
              </Button>
            </div>
            <div className='organization__signBtn'><MainButton onClick={() => { handleRegister() }}>Подписать</MainButton></div>
          </div>
        </div>
      </Container>
    </>
  )
}
