import { authService as service } from '../service'
import { UserStatus } from 'api/UserStatus'
import { CarwashRegisterStep } from 'api/CarwashRegisterStep'

export type Params = never

export type Result = {
  id: string
  email: string
  phone: string
  roles: string[]
  extraPermits: Record<string, string[]>
  status: UserStatus
  carwash_register_step?: CarwashRegisterStep
} | null

export default async function request () {
  return await (service.request('user.me', {}) as Promise<Result>)
}
