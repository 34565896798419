import Boxes from 'pages/Boxes'
import Chat from 'pages/Chat'
import Employees from 'pages/Employees'
import Finance from 'pages/Finance'
import Home from 'pages/Home'
import MyServices from 'pages/MyServices'
import MyService from 'pages/MyService'
import Orders from 'pages/Orders'
import { Navigate } from 'react-router-dom'
import { IRoute } from './types'

export const routes : IRoute[] = [
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/my-services',
    element: <MyServices />
  },
  {
    path: '/my-services/:id',
    element: <MyService />
  },
  {
    path: '/orders',
    element: <Orders />
  },
  {
    path: '/boxes',
    element: <Boxes />
  },
  {
    path: '/employees',
    element: <Employees />
  },
  {
    path: '/finance',
    element: <Finance />
  },
  {
    path: '/chat',
    element: <Chat />
  },
  {
    path: '*',
    element: <Navigate to='/home' />
  }
]
