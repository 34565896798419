import { useState } from 'react'
import { Stepper } from '@autos/autos-common-fe'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import './styles.sass'
import { Button, ButtonBase } from '@mui/material'
import { CarwashRegisterStep } from 'api/CarwashRegisterStep'
import { useAuthContext } from 'AuthContext'

interface Board {
  img_path?: string
  title: string
  desc: string
}

export default function Onboarding () {
  const { changeRegisterStep } = useAuthContext()
  const [activeSteps, setActiveSteps] = useState(1)

  const boards: Board[] = [
    {
      title: 'Название функционала 1',
      desc: 'Описание'
    },
    {
      title: 'Название функционала 2',
      desc: 'Описание'
    },
    {
      title: 'Название функционала 3',
      desc: 'Описание'
    }
  ]

  const item = boards[activeSteps - 1]
  const steps = boards.length

  const nextHandler = () => {
    if (activeSteps < steps) {
      setActiveSteps(prev => prev + 1)
    }
  }

  const submitHandler = () => {
    changeRegisterStep(CarwashRegisterStep.contract).then()
  }

  return (
    <>
      <div className='onboarding'>
        { item && <div className='onboarding__board'>
          <div className='onboarding__image'>
            { item.img_path && <img src={item.img_path} alt={item.title}/> }
          </div>
          <h1>{item.title}</h1>
          <div className='onboarding__desc'>{item.desc}</div>
        </div> }
        <div className='onboarding__row'>
          <Stepper
            steps={steps}
            activeSteps={activeSteps}
          />
        </div>
        <div className='onboarding__row'>
          { activeSteps < steps && <ButtonBase className='onboarding__button' onClick={nextHandler}>Далее <ArrowForwardOutlinedIcon sx={{ width: '20px', height: '20px' }}/></ButtonBase> }
          { activeSteps === steps && <ButtonBase className='onboarding__button' onClick={submitHandler}>Начать работу <ArrowForwardOutlinedIcon sx={{ width: '20px', height: '20px' }}/></ButtonBase> }
        </div>
        <div className='onboarding__row'>
          { activeSteps < steps && <Button style={{ textTransform: 'none', fontSize: '16px' }} onClick={submitHandler}>Пропустить</Button> }
        </div>
      </div>
    </>
  )
}
