import { mainService as service } from 'api/service'

export interface Params {
  name: string
  duration: number
  price: number
  priceDiscount?: number | undefined
  comment?: string | undefined
}

export default async function create (params: Params) {
  return await (service.request('my-offer.create', params) as Promise<boolean>)
}
