import { NavLink, useLocation } from 'react-router-dom'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import './styles.sass'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import OutlinedFlagTwoToneIcon from '@mui/icons-material/OutlinedFlagTwoTone'
import { ActivityIcon } from '@autos/autos-common-fe'
import clsx from 'clsx'

interface NavItem {
  to: string
  title: string
  icon?: JSX.Element
}

export default function Sidebar () {
  const location = useLocation()
  const { pathname } = location
  const items: NavItem[] = [
    {
      to: '/home',
      title: 'Дашборд',
      icon: <GridViewOutlinedIcon />
    },
    {
      to: '/my-services',
      title: 'Мои услуги',
      icon: <CheckOutlinedIcon />
    },
    {
      to: '/orders',
      title: 'Заказы',
      icon: <CalendarTodayOutlinedIcon />
    },
    {
      to: '/boxes',
      title: 'Боксы',
      icon: <OutlinedFlagTwoToneIcon />
    },
    {
      to: '/employees',
      title: 'Сотрудники',
      icon: <PeopleOutlineOutlinedIcon />
    },
    {
      to: '/finance',
      title: 'Финансы',
      icon: <TrendingUpOutlinedIcon />
    }
  ]

  return (
    <>
      <div className='sidebar'>
        <div className='sidebar__top'>
          <div className='sidebar__items'>
          { items.map(({ to, title, icon }, idx) =>
            <div key={idx} className={clsx('sidebar__item', { sidebar__item_active: pathname.includes(to) })}>
              <div className='sidebar__icons'>
                { icon }
              </div>
              <NavLink
                className='sidebar__btnBig'
                to={to}
              >{title}</NavLink>
            </div>)}
          </div>
        </div>
        <div className='sidebar__bottom'>
          <div className={clsx('sidebar__item', { sidebar__item_active: pathname.includes('/chat') })}>
              <div className='sidebar__icons'>
                <ActivityIcon />
              </div>
              <NavLink
                className='sidebar__btnSmall'
                to='/chat'
              >Онлайн чат</NavLink>
            </div>
        </div>
      </div>
    </>
  )
}
