import { createTheme } from '@mui/material/styles'
import shadows, { Shadows } from '@mui/material/styles/shadows'

export const theme = createTheme({
  shadows: shadows.map(() => 'none') as Shadows,
  shape: {
    borderRadius: 1
  },
  palette: {
    primary: {
      main: '#0F172A'
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input + fieldset': {
            borderRadius: '8px'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    }
  }
})
