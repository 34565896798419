import { UserRoles } from 'api/UserRoles'
import { authService as service } from '../service'

export interface Params {
  phone: string
  role: UserRoles.carwash | UserRoles.client
  name?: string
}

export interface Result {
  success: boolean
}

export default async function request (params: Params) {
  return await (service.request('login', params, false) as Promise<Result>)
}
