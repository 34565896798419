import './styles.sass'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { Field, MainButton } from '@autos/autos-common-fe'
import getMapApi, { ID_YANDEX_MAP } from 'common/YandexMap/GetMapApi'
import { useEffect, useRef, useState } from 'react'

export interface Props {
  title: string
  open: boolean
  viewAddress: string
  onClose: () => void
  getAddress: (address: string, coordinates?: ymaps.Coordinate) => void
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    // border: '1px solid #D6D6D6',
    borderRadius: '8px'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

export default function ModalMap ({ title, open, onClose, getAddress, viewAddress }: Props) {
  const myPlacemark = useRef<ymaps.Placemark>()
  const suggestView = useRef<any>()
  const [address, setAddress] = useState<string>(viewAddress)
  const [coordinates, setCoordinates] = useState<ymaps.Coordinate>()
  const [myMap, setMyMap] = useState<ymaps.Map>()
  const [myYamps, setMyYamps] = useState<typeof ymaps>()

  useEffect(() => {
    if (myYamps === undefined) {
      getMapApi().then(mapApi => {
        setMyYamps(mapApi)
      })
    } else if (myMap === undefined) {
      myYamps.ready(() => {
        // @ts-ignore-start
        window?.currentYMap?.destroy()
        // @ts-ignore-end
        const map = new myYamps.Map(ID_YANDEX_MAP, {
          center: [55.76, 37.64],
          controls: [],
          zoom: 10
        })
        setMyMap(map)
        // @ts-ignore-start
        window.currentYMap = map
        // @ts-ignore-end
        suggestView.current = new ymaps.SuggestView('modal-map-suggest')
        suggestView.current.events.add('select', (e: any) => {
          createPlacemarkByAddress(e.get('item').value, map)
        })
        map.events.add('click', (e) => {
          const coords: ymaps.Coordinate = (e as any).get('coords')
          if (myPlacemark.current) {
            myPlacemark.current.geometry.setCoordinates(coords)
          } else {
            myPlacemark.current = createPlacemark(coords)
            map.geoObjects.add(myPlacemark.current)
            myPlacemark.current.events.add('dragend', function () {
              getAddressBycoords(myPlacemark.current?.geometry.getCoordinates() ?? null)
            })
          }
          getAddressBycoords(coords)
        })
        if (viewAddress === address && viewAddress !== '') {
          createPlacemarkByAddress(viewAddress, map)
        }
      })
    }
  })

  const createPlacemark = (coords: ymaps.Coordinate): ymaps.Placemark => {
    return new ymaps.Placemark(coords, {
      iconCaption: 'поиск...'
    }, {
      preset: 'islands#violetDotIconWithCaption',
      draggable: true
    })
  }

  const getAddressBycoords = (coords: ymaps.Coordinate | null) => {
    // @ts-ignore-start
    ymaps.geocode(coords).then(res => {
      const firstGeoObject = res.geoObjects.get(0);
      (myPlacemark.current as any).properties.set({
        iconCaption: [
          firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
          firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
        ].filter(Boolean).join(', '),
        balloonContent: firstGeoObject.getAddressLine()
      })
      setAddress(firstGeoObject.getAddressLine())
      setCoordinates(coords ?? undefined)
    })
    // @ts-ignore-end
  }

  const getCoordinate = async (request: string): Promise<ymaps.Coordinate | null> => {
    const result = await (ymaps as any).geocode(request)
    const obj = result.geoObjects.get(0)
    if (obj === undefined) {
      return null
    }
    return obj.geometry.getCoordinates()
  }

  const createPlacemarkByAddress = (addr: string, ym: ymaps.Map) => {
    getCoordinate(addr).then(coords => {
      if (coords !== null) {
        console.log(coords)
        if (myPlacemark.current) {
          myPlacemark.current.geometry.setCoordinates(coords)
        } else {
          myPlacemark.current = createPlacemark(coords)
          ym.geoObjects.add(myPlacemark.current)
        }
        getAddressBycoords(coords)
        const center = ym.geoObjects.getBounds()
        center !== null && ym.setBounds(center)
        ym.setZoom(ym.getZoom() - 5)
      }
    })
  }

  const save = () => {
    getAddress(address, coordinates)
    onClose()
  }

  return (
    <BootstrapDialog
      onClose={() => onClose()}
      aria-labelledby='ModalMap-dialog-title'
      open={open}
      className='modalMap'
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }}>
        <IconButton
          aria-label='close'
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h1>{ title }</h1>
        <h4>Введите адрес или выберите на карте</h4>
        <div className='modalMap__row'>
          <Field
            label='Адрес'
            size='small'
            fullWidth
            id='modal-map-suggest'
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </div>
        <div className='modalMap__row'>
          <div className='modalMap__map' id={ID_YANDEX_MAP} />
        </div>
      </DialogContent>
      <DialogActions>
        <div className='modalMap__buttonGroup'>
          <div className='modalMap__buttonGroup_left'>
            <MainButton variant='outlined' onClick={() => onClose()}>Назад</MainButton>
          </div>
          <div className='modalMap__buttonGroup_right'>
            <MainButton onClick={save}>Сохранить</MainButton>
          </div>
        </div>
      </DialogActions>
    </BootstrapDialog>
  )
}
