import './styles.sass'
import { Content, FormGroup } from '@autos/autos-common-fe'
import { Navigate, useParams, useNavigate } from 'react-router-dom'
import { Box, Button, TextField } from '@mui/material'
import apiCreate, { Params as CreateParams } from 'api/my-offer/create'
import apiUpdate, { Params as UpdateParams } from 'api/my-offer/update'
import apiGet from 'api/my-offer/get'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useAuthContext } from 'AuthContext'

const defaultData = {
  name: '',
  duration: 0,
  price: 0,
  priceDiscount: 0,
  comment: ''
}

export default function MyService () {
  const [data, setData] = useState<UpdateParams>(defaultData)
  const [found, setFound] = useState<boolean>()
  const params = useParams()
  const navigate = useNavigate()
  const { handleResponseSuccess } = useAuthContext()

  const backHref = '/my-services'
  const { id = '' } = params
  const isNew = id === 'new'

  useEffect(() => {
    if (found !== undefined) {
      return
    }

    if (isNew) {
      setFound(true)
      return
    }

    if (!id.match(/^[\da-f]{24}$/)) {
      setFound(false)
      return
    }

    apiGet(id).then(r => {
      if (r === null) {
        setFound(false)
      } else {
        setFound(true)
        setData(r)
      }
    })
  }, [data, found, id, isNew])

  const updateString = (key: keyof UpdateParams) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [key]: e.target.value })
    }
  }

  const updateInt = (key: keyof UpdateParams) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { value = '' } = e.target
      const parsed = value.match(/^\d+$/) ? parseInt(value) : 0

      setData({ ...data, [key]: parsed })
    }
  }

  const save = useCallback(async () => {
    if (isNew) {
      if (data.name === undefined || data.duration === undefined || data.price === undefined) {
        return
      }

      await apiCreate({
        name: data.name,
        duration: data.duration,
        price: data.price,
        priceDiscount: data.priceDiscount,
        comment: data.comment
      })
    } else {
      await apiUpdate(id, {
        name: data.name,
        duration: data.duration,
        price: data.price,
        priceDiscount: data.priceDiscount,
        comment: data.comment
      })
    }
    handleResponseSuccess('Услуга сохранена')
    navigate(backHref)
  }, [data, handleResponseSuccess, id, isNew, navigate])

  if (found === undefined) {
    return <></>
  }

  if (!found) {
    return <Navigate to='/home' />
  }

  return (
    <Content
      title={id === 'new' ? 'Новая услуга' : `Моя услуга #${id}`}
      back={backHref}
    >
      <Box sx={{ borderTop: '1px solid #B8B8B8', paddingTop: '1em' }}>
        <FormGroup title='Общие данные'>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', alignItems: 'top', marginRight: '1em' }}>
              <Box sx={{
                width: '228px',
                height: '228px',
                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.96), rgba(255, 255, 255, 0.96)), #000000'
              }}></Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '1em' }}>
              <Box sx={{ width: '100%', display: 'block' }}>
                <TextField size='small' label="Название услуги" sx={{ width: '100%' }} value={data.name} onChange={updateString('name')} />
              </Box>
              <Box sx={{ width: '100%', display: 'block' }}>
                <TextField size='small' label="Описание" multiline rows={4} sx={{ width: '100%' }} value={data.comment} onChange={updateString('comment')} />
              </Box>
              <Box sx={{ width: '100%', display: 'flex', gap: '1em', flexGrow: '1' }}>
                <TextField size='small' label="Продолжительность" sx={{ width: '100%' }} value={data.duration} onChange={updateInt('duration')} />
                <TextField size='small' label="Цена" sx={{ width: '100%' }} value={data.price} onChange={updateInt('price')} />
                <TextField size='small' label="Цена со скидкой" sx={{ width: '100%' }} value={data.priceDiscount} onChange={updateInt('priceDiscount')} />
              </Box>
            </Box>
          </Box>
        </FormGroup>
        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em' }}>
          <Button variant='outlined' href={backHref}>Отмена</Button>
          <Button variant='contained' onClick={ () => { save() }}>Сохранить</Button>
        </Box>
      </Box>
    </Content>
  )
}
