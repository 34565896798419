import './styles.sass'
import { Checkbox, Stack } from '@mui/material'
import { useState } from 'react'
import { useAuthContext } from '../../AuthContext'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { Field, MainButton } from '@autos/autos-common-fe'
import { UserRoles } from 'api/UserRoles'

export function Login () {
  const { login, handleResponseFailure } = useAuthContext()
  const [phone, setPhone] = useState<string>('')
  const [consent, setConsent] = useState<boolean>(false)

  const submitHandler = () => {
    if (!consent) {
      handleResponseFailure('Необходимо подтвердить что вы ознакомились с Политикой конфиденциальности и Условиями сервиса')
    }

    if (typeof phone === 'string' && consent) {
      login({ phone, role: UserRoles.carwash }).then((success) => {
        if (!success) {
          handleResponseFailure('Произошла ошибка при входе пользователя повторите попытку позже.')
        }
      })
    }
  }

  return (
      <>
        <div className='AuthForm'>
          <div className='AuthForm__body'>
            <h1>Вход в приложение</h1>
            <h3>Введите свой номер телефона</h3>
            <div className='AuthForm__row'>
              <Field
                id='outlined-phone-input'
                label='Номер телефона'
                type='text'
                fullWidth
                size='small'
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className='AuthForm__row'>
              <Stack direction='row' justifyContent='space-between' alignItems='start' spacing={2}>
                <Checkbox checked={consent} onChange={(e) => setConsent(e.target.checked) } className='AuthForm__checkbox'/>
                <h3 style={{ textAlign: 'left' }}>Я подтверждаю, что ознакомился с Политикой конфиденциальности и Условиями сервиса</h3>
              </Stack>
            </div>
            <div className='AuthForm__row'>
              <MainButton sx={{ width: '100%' }} onClick={submitHandler}>Продолжить <ArrowForwardOutlinedIcon sx={{ width: '20px', height: '20px' }}/></MainButton>
            </div>
          </div>
        </div>
      </>
  )
}
