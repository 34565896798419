import { OrganizationInterface } from 'api/OrganizationInterface'
import { mainService as service } from '../service'

export type Params = Partial<OrganizationInterface>

export type Result = boolean

export default async function request (params: Params) {
  return await (service.request('my-organization.register', params) as Promise<Result>)
}
