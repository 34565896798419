import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { AuthContextProvider } from './AuthContext'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { theme } from './theme'
import App from './App'

import 'react-toastify/dist/ReactToastify.min.css'
import { ThemeProvider } from '@mui/material'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
      <ToastContainer
        position='bottom-left'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  </StrictMode>
)
