import { authService as service } from '../service'

export interface Params {
  code: string
}

export interface Result {
  success: boolean
  attempts_left: number
}

export default async function request (params: Params) {
  return await (service.request('login2', params, false) as Promise<Result>)
}
