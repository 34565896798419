import { CarwashRegisterStep } from 'api/CarwashRegisterStep'
import { authService as service } from 'api/service'

export type Params = {
  id: string
  carwash_register_step: CarwashRegisterStep
}

export type Result = boolean

export default async function request (params: Params) {
  return await (service.request('user.set-register-step', params) as Promise<Result>)
}
