import { authService as service } from '../service'
import { SessionStatus } from '../SessionStatus'

export type Params = never

export interface Result {
  status: SessionStatus
}

export default async function request () {
  const result = await service.request('session-status', {}, false) as {status: unknown, email: string | null}

  return {
    status: result.status === null ? SessionStatus.inactive : result.status as SessionStatus
  }
}
