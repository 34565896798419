import './styles.sass'

export function AuthLayout ({ content }: { content: JSX.Element}) {
  return (
    <>
      <div className='AuthLayout'>
        { content }
      </div>
    </>
  )
}
