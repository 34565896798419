import { PropsWithChildren, createContext, useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { SessionStatus } from './api/SessionStatus'
import apiSessionStatus from './api/auth/session-status'
import apiLogin, { Params as LoginParams } from './api/auth/login'
import apiLogin2, { Params as Login2Params, Result as Login2Result } from './api/auth/login2'
import apiMe, { Result as meResult } from './api/user/me'
import apiLogout from './api/auth/logout'
import apiSendSmsCode, { Result as SendSmsCodeResult } from './api/auth/send-sms-code'
import apiChangeRegisterStep, { Result as ChangeRegisterStepResult } from 'api/user/SetRegisterStep'
import { CarwashRegisterStep } from 'api/CarwashRegisterStep'

interface Context {
  login: (params: LoginParams) => Promise<boolean>
  login2: (params: Login2Params) => Promise<Login2Result>
  logout: () => Promise<boolean>
  sendSmsCode: () => Promise<SendSmsCodeResult>
  me: () => Promise<meResult>
  handleResponseFailure: (error: string) => void
  handleResponseSuccess: (message?: string) => void
  changeRegisterStep: (step: CarwashRegisterStep) => Promise<ChangeRegisterStepResult>
  sessionStatus?: SessionStatus
  phone?: string
  userId?: string
  carwashRegisterStep?: CarwashRegisterStep
}

const AuthContext = createContext<Context | null>(null)

export function AuthContextProvider ({ children }: PropsWithChildren<{}>) {
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>()
  const [phone, setPhone] = useState<string>()
  const [userId, setUserId] = useState<string>()
  const [carwashRegisterStep, setCarwashRegisterStep] = useState<CarwashRegisterStep>()

  useEffect(() => {
    if (sessionStatus === undefined) {
      apiSessionStatus().then(result => {
        setSessionStatus(result.status)
      })
    }
  }, [sessionStatus, setSessionStatus])

  useEffect(() => {
    if (carwashRegisterStep === undefined) {
      me().then(result => {
        setCarwashRegisterStep(result?.carwash_register_step)
      })
    }
  }, [carwashRegisterStep, sessionStatus, setSessionStatus])

  const login = async (params: LoginParams): Promise<boolean> => {
    if (sessionStatus !== SessionStatus.inactive) {
      return false
    }

    const result = await apiLogin(params)

    if (result.success) {
      setPhone(params.phone)
      setSessionStatus(SessionStatus.new)
    }

    return result.success
  }

  const login2 = async (params: Login2Params): Promise<Login2Result> => {
    if (sessionStatus !== SessionStatus.new) {
      return {
        success: false,
        attempts_left: 0
      }
    }

    const result = await apiLogin2(params)

    if (result.success) {
      setSessionStatus(SessionStatus.active)
      await apiMe()
    }

    return result
  }

  const logout = async (): Promise<boolean> => {
    const success = await apiLogout()

    if (success) {
      setPhone(undefined)
      setSessionStatus(SessionStatus.inactive)
      setCarwashRegisterStep(undefined)
    }

    return success
  }

  const sendSmsCode = async (): Promise<SendSmsCodeResult> => {
    if (sessionStatus !== SessionStatus.new) {
      return {
        success: false,
        cooldown_mts: 0
      }
    }

    return await apiSendSmsCode()
  }

  const me = async (): Promise<meResult> => {
    const result = await apiMe()

    if (result) {
      setCarwashRegisterStep(result.carwash_register_step)
      setUserId(result.id)
      setPhone(result.phone)
    }

    return result
  }

  const changeRegisterStep = async (step: CarwashRegisterStep): Promise<ChangeRegisterStepResult> => {
    if (userId === undefined) {
      return false
    }

    const result = await apiChangeRegisterStep({ id: userId, carwash_register_step: step })

    if (result) {
      await me()
    }

    return result
  }

  const handleResponseFailure = (error: string) => {
    toast(error)
  }

  const handleResponseSuccess = (message?: string) => {
    toast(message)
  }

  const value = {
    login,
    login2,
    logout,
    sendSmsCode,
    me,
    changeRegisterStep,
    handleResponseFailure,
    handleResponseSuccess,
    sessionStatus,
    phone,
    carwashRegisterStep,
    userId
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext () {
  const context = useContext(AuthContext)

  if (context == null) {
    throw new Error('Used outside of "AuthContextProvider"')
  }

  return context
}
