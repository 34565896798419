import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import './styles.sass'

export function MainLayout () {
  return (
    <>
      <div className='mainLayout'>
        <Navbar />
        <div className='mainLayout__body'>
          <div className='mainLayout__sidebar'>
            <Sidebar />
          </div>
          <div className='mainLayout__content'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}
