import { mainService as service } from '../service'

export type Params = {
  code: string
}

export type Result = boolean

export default async function request (params: Params) {
  return await (service.request('my-organization.sign', params) as Promise<Result>)
}
