import { authService as service } from '../service'

export type Params = never

export interface Result {
  success: boolean
  cooldown_mts: number
}

export default async function request () {
  return await (service.request('send-sms-code', {}, false) as Promise<Result>)
}
