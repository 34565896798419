import { Stack } from '@mui/material'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import './styles.sass'
import { useCallback, useEffect, useState } from 'react'
import { useAuthContext } from 'AuthContext'
import { Field, MainButton, Pieces } from '@autos/autos-common-fe'
import { ContractStatus } from '..'
import apiSign from 'api/organization/myOrganizationSign'
import apiSend from 'api/organization/sendSmsCode'

interface Props {
  setContractStatus: React.Dispatch<React.SetStateAction<ContractStatus>>
}

export default function SignCode ({ setContractStatus }: Props) {
  const { handleResponseSuccess, handleResponseFailure, phone } = useAuthContext()
  const [code, setCode] = useState<string>('')
  const [coolDownMts, setCoolDownMts] = useState<number>(0)

  const sendSmsHandler = useCallback(() => {
    apiSend().then(({ success, cooldown_mts: cooldownMts }) => {
      if (success) {
        handleResponseSuccess(`Код отправлен на номер ${phone}`)
      }

      let countDownMts = cooldownMts
      setCoolDownMts(cooldownMts)

      const interval = setInterval(() => {
        if (countDownMts > 1000) {
          countDownMts -= 1000
        } else {
          countDownMts = 0
          clearInterval(interval)
        }

        setCoolDownMts(countDownMts)
      }, 1000)
    })
  }, [handleResponseSuccess, phone])

  useEffect(() => {
    sendSmsHandler()
  }, [sendSmsHandler])

  const submitHandler = () => {
    if (typeof code === 'string' && code.length > 0) {
      apiSign({ code }).then(success => {
        if (!success) {
          handleResponseFailure('Не верный код')
        } else {
          setContractStatus(ContractStatus.success_signed)
        }
      })
    }
  }

  const coolDown = Math.round(coolDownMts / 1000)

  return (
    <>
     <div className='SignCodeForm'>
        <div className='SignCodeForm__body'>
          <h1>Подписание договора</h1>
          <h3>Мы отправили код подтверждения на указанный номер</h3>
          <div className='SignCodeForm__row'>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
              <Field
                type='text'
                className='SignCodeForm__field'
                fullWidth
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder='1234'
              />
            </Stack>
          </div>
          <div className='SignCodeForm__row'>
            { coolDownMts > 0 && <MainButton sx={{ width: '100%' }} onClick={submitHandler}>Подтвердить <ArrowForwardOutlinedIcon sx={{ width: '20px', height: '20px' }}/></MainButton> }
            { coolDownMts <= 0 && <MainButton sx={{ width: '100%' }} onClick={sendSmsHandler}>Отправить код</MainButton> }
          </div>
          <div className='SignCodeForm__row'>
            { coolDownMts > 0 && <h4>(Повторно код можно получить через {coolDown} {Pieces(coolDown, 'секунду', 'секунды', 'секунд')})</h4> }
          </div>
        </div>
      </div>
    </>
  )
}
