import { authService, mainService } from '../service'

export type Params = never

export type Result = boolean

export default async function request () {
  authService.logout()
  mainService.logout()
  return await (authService.request('logout', {}, false) as Promise<Result>)
}
