import { Routes, Route } from 'react-router-dom'
import { routes } from 'routes'
import { IRoute } from './routes/types'

import { AuthLayout } from './layouts/AuthLayout'
import { MainLayout } from './layouts/MainLayout'

import { Login } from './pages/Login'

import { useAuthContext } from './AuthContext'
import { SessionStatus } from './api/SessionStatus'
import AuthCode from 'pages/AuthCode'
import { CarwashRegisterStep } from 'api/CarwashRegisterStep'
import Onboarding from 'pages/Onboarding'
import Contract from 'pages/Contract'

export default function App () {
  const { sessionStatus, carwashRegisterStep } = useAuthContext()

  if (sessionStatus === SessionStatus.inactive) {
    return <AuthLayout content={ <Login /> }/>
  } else if (sessionStatus === SessionStatus.new) {
    return <AuthLayout content={ <AuthCode /> }/>
  } else if (sessionStatus === SessionStatus.active && carwashRegisterStep === CarwashRegisterStep.onboarding) {
    return <AuthLayout content={ <Onboarding /> }/>
  } else if (sessionStatus === SessionStatus.active && carwashRegisterStep === CarwashRegisterStep.contract) {
    return <AuthLayout content={ <Contract /> }/>
  } else if (sessionStatus === SessionStatus.active && carwashRegisterStep === CarwashRegisterStep.done) {
    return (
      <Routes>
        <Route element={<MainLayout />}>
          { routes.map(({ path, element }: IRoute, idx) => <Route key={idx.toString()} path={path} element={element} />) }
        </Route>
      </Routes>
    )
  } else {
    return <></>
  }
}
